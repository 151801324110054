.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker__triangle {
  transform: translate3d(20px, 2px, 0px) !important;
}

.react-datepicker__input-container input {
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 3px;
  margin-top: 4px;
  width: 100%;
}

.react-datepicker__input-container input:focus {
  outline: none !important;
  border: 1px solid #9ea5a9;
}
