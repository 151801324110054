.react-tags {
  /* position: relative;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 16px;
  max-height: 52px;
  cursor: text; */
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: flex;
  margin: 12px 6px;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  padding: 6px 8px;
  margin-top: -6px;
  margin-left: -6px;
  margin-right: 12px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  background: #f1f1f1;
  cursor: pointer;

  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  background: #f1f1f1;
}

.react-tags__search {
  display: inline-block;
  max-width: 100%;
  width: 100% !important;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    position: relative;
  }
}

.react-tags__search-input {
  width: 100% !important;
  max-width: 100%;

  margin: 0;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: none;

  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  z-index: 100;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 3px;
  list-style: none;
  background: #fff;
  border: 0px solid #d1d1d1;
  border-radius: 5px;
}

.react-tags__suggestions li {
  border-radius: 5px;
  padding: 8px 8px;
}

.react-tags__suggestions li:not(:first-child) {
  border-top: 1px solid #f1f1f1;
}

.react-tags__suggestions li mark {
  background: none;
  font-weight: 800;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #f1f1f1;
}

.react-tags__suggestions li.is-active {
  background: #f1f1f1;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
